.projects__grid {
  max-width: 1100px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25em, 1fr));
  grid-gap: 2em;
}

/* Add media query for medium-sized screens */
@media (max-width: 1024px) {
  .projects__grid {
    grid-template-columns: repeat(auto-fit, minmax(20em, 1fr)); /* Adjust to fit more cards */
  }
}

/* Add media query for smaller screens */
@media (max-width: 950px) {
  .projects__grid {
    grid-template-columns: 1fr; /* Single column on smaller screens */
    grid-gap: 1em;
  }
}
