.about {
  margin-top: 3em;
}

.about__title {
  text-align: center;
  margin-bottom: 1em;
}

.about__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.about__name {
  color: var(--clr-primary);
}

.about__left {
  flex: 1;
}

.about__right {
  flex: 1;
  display: flex;
  justify-content: center;
  margin-left: 2em;
}

.about__role {
  margin-top: 1.2em;
}

.about__desc {
  font-size: 1rem;
  max-width: 900px;
}

.about__desc,
.about__contact {
  margin-top: 2.4em;
}

.about__contact {
  display: flex;
  align-items: center;
}

.btn--outline {
  width: auto;
  padding: 0.6em 1em;
  font-size: 0.9rem;
  white-space: nowrap;
  margin-right: 1em;
}

.about .link--icon {
  margin-right: 0.8em;
  color: var(--clr-primary);
}

.about__image {
  max-width: 80%;
  max-height: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: contain;
}

@media (max-width: 900px) {
  h2.about__role {
    font-size: 1.6rem;
  }
  
  .about__content {
    flex-direction: column;
    align-items: center;
  }

  .about__right {
    order: -1;
    margin-left: 0;
    margin-bottom: 2em;
  }
  .about__image {
    max-width: 450px;
    width: 80%;
    max-height: 100%;
    height: auto;
    border-radius: 8px;
    object-fit: contain;
  }

  .about__left {
    text-align: center;
  }

  .about__desc,
  .about__contact {
    margin-top: 1.5em;
    text-align: center; /* Center the text content */
    justify-content: center;
  }

  .about__contact {
    justify-content: center; /* Center the button and icons */
  }
}

@media (max-width: 500px) {
  h2.about__role {
    font-size: 1.4rem;
  }
}
