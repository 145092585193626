.timeline-section {
    padding: 2em 0;
    position: relative;
  }
  
  .timeline {
    list-style: none;
    padding: 0;
    position: relative;
    margin: 0;
  }
  
  .timeline::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 4px;
    background: var(--clr-primary);
    left: 50%;
    margin-left: -2px;
    z-index: 1;
  }
  
  .timeline-item {
    position: relative;
    margin-bottom: 2em;
    clear: both;
  }
  
  .timeline-item::before,
  .timeline-item::after {
    content: "";
    display: table;
  }
  
  .timeline-item::after {
    clear: both;
  }
  
  .timeline-panel {
    width: 45%;
    padding: 1em;
    background: var(--clr-bg-alt);
    border-radius: 8px;
    box-shadow: var(--shadow);
    position: relative;
    text-align: left;
    float: left;
  }
  
  .timeline-inverted .timeline-panel {
    float: right;
  }
  
  .timeline-badge {
    width: 40px;
    height: 40px;
    background: var(--clr-primary);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -20px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .timeline-badge img {
    width: 24px;
    height: 24px;
  }
  
  .timeline-title {
    margin-bottom: 0.5em;
    font-weight: bold;
  }
  
  .timeline-company {
    color: var(--clr-fg-alt);
    font-style: italic;
  }
  
  .timeline-body {
    margin-top: 1em;
  }
  
  /* Responsive adjustments */
  @media (max-width: 767px) {
    .timeline::before {
      left: 10px;
    }
  
    .timeline-item .timeline-panel {
      width: calc(100% - 60px);
      float: right;
    }
  
    .timeline-inverted .timeline-panel {
      float: right;
    }
  
    .timeline-item .timeline-badge {
      left: 10px;
      margin-left: 0;
    }
  }
  